import React from 'react';
import {Route, Switch} from "react-router";
import NewsPage, {Article} from "./NewsPage";
import {withTranslation} from "react-i18next";

function NewsNavRoute({i18n}) {
    return (
        <Switch>
            <Route path="/news/article-:articleId" render={(props) => <Article {...props} i18n={i18n} />} />
            <Route path="/news" component={NewsPage}/>
        </Switch>
    );
}

export default withTranslation()(NewsNavRoute);