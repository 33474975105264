import React, {useEffect, useState} from "react";
import './Demo.css';
import arrow from './media/img/right-arrow.svg';
import {useTranslation, withTranslation} from "react-i18next";
import {resources_page, wordpress_acf_url} from "./variables";

export function DemoItem({name, description, img_path, style, className, url}) {
    const {t} = useTranslation();
    return (
        <div className={'demoitem ' + className} style={style}>
            <a href={url} rel="noopener noreferrer" target={"_blank"}>
                <div className={'demologo'}>
                    <img src={require('./media/img/' + img_path)} alt={t('Demo logo')} />
                </div>
                <div className={'text'}>
                    <h4>{name}</h4>
                    <p>{description}</p>
                </div>
                <img className={'arrow'} src={arrow} alt={'Go'}/>
            </a>
        </div>
    );
}

function ResourceItem({name, style, url}) {
    const {t} = useTranslation();
    return (
        <div className={'demoitem without-img'} style={style}>
            <a href={url} rel="noopener noreferrer" target="_blank">
                <p>{name}</p>
                <img className={'arrow'} src={arrow} alt={t('Go')}/>
            </a>
        </div>
    );
}

function DemoBlock({title, data}) {
    const lastStyle = {
        border: 'none',
    };

    const {t, i18n} = useTranslation();
    const [resources, setResources] = useState([]);

    useEffect(() => {
        const lang = i18n.language.split('-')[0];
        fetch(wordpress_acf_url + '/pages/' + resources_page + '?lang=' + lang)
            .then(response => response.json())
            .then(result => {
                setResources(result.acf.resource);
            })
            .catch(err => console.error(err));

    }, [i18n.language]);

    if (title === t('Demos')) {
        if (data.length === 0) {
            return null;
        }

        const blocks = data.map(({name, description, img_path, className, url}, index) => {
            if (index === data.length - 1) {
                return <DemoItem style={lastStyle} key={index} img_path={img_path} name={name} description={description}
                                 className={className} url={url}/>
            }
            else {
                return <DemoItem key={index} img_path={img_path} name={name} description={description} className={className} url={url}/>
            }
        });
        return (
            <div className={'demoblock'}>
                <h2 className={'small-title'}>{title}</h2>
                <div>{blocks}</div>
            </div>
        );
    } else {
        const blocks = resources.map((item, index) => {
            if (index === resources.length - 1) {
                return <ResourceItem style={lastStyle} key={index} name={item.name} url={item.url} />
            }
            else {
                return <ResourceItem key={index} name={item.name} url={item.url} />
            }
        });
        return (
            <div className={'demoblock'}>
                <h2 className={'small-title'}>{title}</h2>
                <div>{blocks}</div>
            </div>
        );
    }
}

function DemoPage(props) {
    return (
        <div className={"App demoPage"}>
            <h1 className={"title"}>{props.i18n.t('Demos and resources')}</h1>
            <DemoBlock title={props.i18n.t('Demos')} data={props.i18n.t('demoData', {returnObjects: true})}/>
            <DemoBlock title={props.i18n.t('Language technology resources')} />
        </div>
    );
}

export default withTranslation()(DemoPage);