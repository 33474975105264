import React, {useEffect, useState} from "react";
import './Collaboration.css';
import {ProjectBlock} from "./CollaborationProject";
import {useTranslation} from "react-i18next";
import {collaboration_category, wordpress_url} from "./variables";

function CollaborationPage() {
    const {t, i18n} = useTranslation();
    const [text, setText] = useState('');

    useEffect(() => {
        const lang = i18n.language.split('-')[0];
        fetch(`${wordpress_url}/pages/1099?lang=${lang}`)
            .then(response => response.json())
            .then(result => {
                setText(result.content.rendered);
            })
            .catch(e => console.error(e));
    }, [i18n.language]);

    return (
        <div className={"App demoPage"}>
            <h1 className={'title'}>{t('Collaboration')}</h1>
            <div className={'description collab'}>
                <h2 className={'empty-title'}> </h2>
                <p className={'big collab'} dangerouslySetInnerHTML={{ __html: text}}>
                </p>
            </div>
            <ProjectBlock name={t('Collaboration projects')} category_nr={collaboration_category} i18n={i18n}/>
        </div>
    );
}

export default CollaborationPage;