import React from "react";
import arrow from './media/img/right-arrow.svg';
import {Link} from "react-router-dom";
import ReactSVG from "react-svg";
import "./BranchMenu.css";
import {useMediaQuery} from "react-responsive";
import {useWindowSize} from "./functions";
import {useTranslation} from "react-i18next";

export const BranchSmall = ({name, url, clickHandler}) => {
    return (
        <div className={'BranchSmall'} onClick={clickHandler}>
            <Link to={'/branches/' + url}>
                <h3>{name}</h3>
                <ReactSVG src={arrow} alt={'Nool'} className={'arrow'}/>
            </Link>
        </div>
    );
};

export const BranchWithoutRipple = ({name, img_path, url, className}) => {
    return (
        <div className={'Branch without-ripple ' + className}>
            <Link to={'/branches/' + url}>
                <ReactSVG src={require('./media/img/' + img_path)} className={"BranchLogo " + className} />
                <div className={'description'}>
                    <h1>{name}</h1>
                    <ReactSVG src={arrow} alt={'Nool'} className={'arrow'}/>
                </div>
            </Link>
        </div>
    );
};

const Branch = ({text, img_path, url, className}) => {
    function rippleEffect(e) {
        const targetEl = e.currentTarget.querySelector('.BranchLogo');
        let inkEl = targetEl.querySelector('.ink');

        if (inkEl === null) {
            inkEl = document.createElement('span');
            inkEl.classList.add('ink');
            inkEl.style.width = inkEl.style.height = Math.max(targetEl.offsetWidth, targetEl.offsetHeight) + 'px';
            targetEl.appendChild(inkEl);

        }

        inkEl.style.left = (e.offsetX - inkEl.offsetWidth / 2) + 'px';
        inkEl.style.top = (e.offsetY - inkEl.offsetHeight / 2) + 'px';
        inkEl.classList.add('animate');
        e.currentTarget.classList.add('ripple');
    }

    function removeRipple(e) {
        const targetEl = e.currentTarget.querySelector('.BranchLogo');
        let inkEl = targetEl.querySelector('.ink');

        if (inkEl) {
            inkEl.classList.remove('animate');
            e.currentTarget.classList.remove('ripple');
        }
    }

    return (
        <div className={'Branch ' + className} onMouseEnter={rippleEffect} onMouseLeave={removeRipple}>
            <Link to={'/branches/' + url}>
                <ReactSVG src={require('./media/img/' + img_path)} className={'BranchLogo ' + className}/>
                <div className={'description'}>
                    <h1>{text}</h1>
                    <ReactSVG src={arrow} alt={'Nool'} className={'arrow'} />
                </div>
            </Link>
        </div>
    );
};

const BranchMenu = () => {
    const {t, } = useTranslation();
    const isBigMobile = useMediaQuery({minWidth: 860});
    const isTablet = useMediaQuery({minWidth: 1024});
    const isSmallDesktop = useMediaQuery({minWidth: 1280});
    const isNormalDesktop = useMediaQuery({minWidth: 1440});
    const isBigDesktop = useMediaQuery({minWidth: 1980});
    const isExtraBigDesktop = useMediaQuery({minWidth: 2560});
    const [width, height] = useWindowSize();
    const aspectRatio = width / height;

    let newHeight;
    if (isBigMobile) {
        if (isExtraBigDesktop && aspectRatio > 2.2) {
            newHeight = 950;
        }
        else if (isBigDesktop && aspectRatio > 2.2) {
            newHeight = 675;
        }
        else if (isNormalDesktop && aspectRatio > 2.08) {
            newHeight = 475;
        }
        else if (isSmallDesktop && aspectRatio > 1.88) {
            newHeight = 450;
        }
        else if (isTablet && aspectRatio > 1.66) {
            newHeight = 390;
        }
        else if (isBigMobile && aspectRatio > 1.4) {
            newHeight = 350;
        }
        else {
            // university logo, header, scroll button, scroll button margin
            newHeight = height - 46 - 67 - 46 - 40;
        }
    }
    else {
        newHeight = height - 46 - 67;
    }

    return (
        <>
            <div id={'BranchMenu'} style={{minHeight: `${newHeight}px`}}>
                {t('branches', {returnObjects: true}).map(({url, name, img_path, className}, index) => {
                    return <Branch key={index} text={name} url={url} img_path={img_path} className={className} />;
                })}
            </div>
            <div className={'scrollbutton'}>
                <div className={'circle'}/>
            </div>
        </>
    );
}

export default BranchMenu;
