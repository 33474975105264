import React, {Fragment, useEffect, useState} from 'react';
import "react-multi-carousel/lib/styles.css";
import BranchMenu from "./BranchMenu";
import {PeopleBlock} from "./People";
import {news_category, wordpress_url} from "./variables";
import {DemoItem} from "./DemoPage";
import rightarrow from './media/img/right-arrow.svg';
import {Link} from "react-router-dom";
import {longDateOptions, NewsSmall} from "./NewsPage";
import {useTranslation} from "react-i18next";
import ReactSVG from "react-svg";
import {ContactForm} from "./ContactPage";


function DemoBlock({i18n}) {
    const demos = i18n.t('demoData', {returnObjects: true});
    const demosDiv = demos.map((item, index) => <DemoItem name={item.name} key={index} img_path={item.img_path}
                                                          description={item.description} className={item.className} url={item.url}/>);

    return (
        <div className={'demoblock main-page'}>
            <h1 className={'title vertical'}>{i18n.t('Demos')}</h1>
            <div>
                {demosDiv}
                <button>
                    <Link to={'/demos'} className={'button-href'}>
                        {i18n.t('All demos')}
                        <ReactSVG src={rightarrow} alt={i18n.t('Go')} className={'arrow'}/>
                    </Link>
                </button>
            </div>

        </div>
    );
}

const NewsBlock = ({match, i18n}) => {
    const [latestNews, setLatestNews] = useState('');

    useEffect(() => {
        fetch(wordpress_url + '/posts?per_page=2&categories=' + news_category + '&lang=' + i18n.language)
            .then(response => response.json())
            .then(data => {
                let newsJson = [];
                for (let i = 0; i < data.length; i++) {
                    let formattedDate = new Date(data[i].date);
                    const locale = i18n.language === 'et' ? 'et-ET' : 'en-US';
                    data[i].date = formattedDate.toLocaleDateString(locale, longDateOptions);
                    newsJson.push(data[i]);
                }
                setLatestNews(newsJson);
            });
    }, [i18n.language]);

    const oldMatch = match;
    oldMatch.url = '/news';

    return (
        <div className={'flexdiv main-page'}>
            <h1 className={'title vertical'}>{i18n.t('News')}</h1>
            {latestNews ? (
                <div>
                    <div className={'newscolumn'}>
                        {latestNews.map(({id, title, date, excerpt, fimg_url}) => {
                                return <NewsSmall key={id} id={id} title={title} date={date} excerpt={excerpt}
                                                  image={fimg_url}
                                                  match={oldMatch}/>
                            }
                        )}
                    </div>
                    <button>
                        <Link to={'/news'} className={'button-href'}>
                            {i18n.t('All news')}
                            <ReactSVG src={rightarrow} alt={'Mine'} className={'arrow'}/>
                        </Link>
                    </button>
                </div>
            ) : (
                <div className={'loading'}>{i18n.t('Loading')}</div>
            )
            }
        </div>
    );
};

const ContactBlock = ({ contactTitle, contactText }) => {
    const {t, } = useTranslation();

    return (
        <div className={'addressblock main-page'}>
            <h1 className={'title vertical vertical2'}>{t('Write to us')}</h1>
            <div className={'addresscolumn'}>
                <h3 className={'small-title'}>{contactTitle}</h3>
                <p className={'big'} dangerouslySetInnerHTML={{__html: contactText}} />
                <ContactForm />
            </div>
        </div>
    );
};

function App({match}) {
    // eslint-disable-next-line no-unused-vars
    const {t, i18n} = useTranslation();
    const [peopleText, setPeopleText] = useState('');
    const [contactText, setContactText] = useState('');
    const [contactTitle, setContactTitle] = useState('');
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        const lang = i18n.language.split('-')[0];
        fetch(`${wordpress_url}/pages/1085?lang=${lang}`)
            .then(response => response.json())
            .then(result => {
                //console.log(result);
                setContactText(result.acf.contact_text);
                setContactTitle(result.acf.contact_title);
                setPeopleText(result.acf.team_text);
                setImageUrl(result.fimg_url);
            })
            .catch(e => console.error(e));
    }, [i18n.language]);

    return (
        <Fragment>
            <BranchMenu i18n={i18n}/>
            <div id={"grid"}>
                <DemoBlock i18n={i18n}/>
                <NewsBlock match={match} i18n={i18n}/>
                <PeopleBlock className={'main-page'} peopleText={peopleText} imageUrl={imageUrl} />
                <ContactBlock contactText={contactText} contactTitle={contactTitle} />
            </div>
        </Fragment>
    );
}

export default App;
