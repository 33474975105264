import logo from "./media/img/Tartunlp_logo.svg";
import './Header.css';
import React, {useState, useEffect, Fragment, useRef} from "react";
import {Link, NavLink, useLocation} from "react-router-dom";
import ReactSVG from "react-svg";
import universityLogoLongEst from './media/img/tartu_ylikool-põhilogo_valge-est.svg';
import universityLogoLongEng from './media/img/tartu_ylikool-põhilogo_valge-eng.svg';
import arrow from './media/img/right-arrow.svg';
import {faBars, faTimes, faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {withTranslation} from "react-i18next";
import {BranchSmall, BranchWithoutRipple} from "./BranchMenu";
import {useMediaQuery} from "react-responsive/src";

const UTLogo = ({language}) => {
    const lang = language.split('-')[0];
    if (lang === 'et') {
        return <ReactSVG src={universityLogoLongEst} id={'university-logo'}/>;
    } else {
        return <ReactSVG src={universityLogoLongEng} id={'university-logo'}/>
    }
};

function Navigation(props) {
    const [lang, setLang] = useState(props.i18n.language);
    const [navBarOpen, toggleNavBar] = useState(false);
    const [teachingDropDownOpen, toggleTeachingDropDown] = useState(false);
    const [branchesDropDownOpen, toggleBranchesDropDown] = useState(false);
    const isDesktop = useMediaQuery({'query': '(min-width: 1024px)'});
    const [branchesActive, setBranchesActive] = useState(false);
    const [teachingActive, setTeachingActive] = useState(false);
    const [yOffset, setYoffset] = useState(0);

    let location = useLocation();
    useEffect(() => {
        // every time the URL changes, close the dropdowns
        toggleBranchesDropDown(false);
        toggleTeachingDropDown(false);

        if (location.pathname.match(/^\/teaching/)) {
            setTeachingActive(true);
        } else {
            setTeachingActive(false);
        }

        if (location.pathname.match(/^\/branches/)) {
            setBranchesActive(true);
        } else {
            setBranchesActive(false);
        }
    }, [location]);

    const handleLangChange = event => {
        let newLang = event.target.id;
        props.i18n.changeLanguage(newLang)
            .then(() => {
                setLang(newLang);
            });
    };

    const handleNavBar = event => {
        // Set Y offset before opening the navigation menu to return to the position the user was previously on
        if (!navBarOpen) {
            setYoffset(window.pageYOffset);
        }

        if (!isDesktop && !(event.currentTarget.classList.contains('logo') || event.target.classList.contains('logo'))) {
            const root = document.getElementById('root');

            const items = document.getElementsByClassName('item');
            for (let i = 0; i < items.length; i++) {
                if (items[i].classList.contains('active')) {
                    items[i].classList.remove('active');
                    root.classList.remove('fixed');
                } else {
                    items[i].classList.add('active');
                    root.classList.add('fixed');
                }
            }

            // If the navigation menu was open, let it change the style (CSS) first and then scroll to the previous Y offset
            if (navBarOpen) {
                window.scrollTo(0, yOffset);
            }

            toggleNavBar(!navBarOpen);
        }

        if (teachingDropDownOpen) {
            toggleTeachingDropDown(false);
        }
        if (branchesDropDownOpen) {
            toggleBranchesDropDown(false);
        }
    };

    const handleTeachingDropDown = () => {
        if (branchesDropDownOpen) toggleBranchesDropDown(false);
        toggleTeachingDropDown(!teachingDropDownOpen);
    };

    const handleBranchesDropDown = () => {
        if (teachingDropDownOpen) toggleTeachingDropDown(false);
        toggleBranchesDropDown(!branchesDropDownOpen);
    };

    const style = {
        opacity: '1.0',
    };

    return (
        <nav className={'sticky-inner ' + (navBarOpen ? 'active' : '')}>
            <ul className="menu">
                <li className="logo" onClick={handleNavBar}><Link to="/"><ReactSVG src={logo}/></Link></li>
                <li id={'branches-dropdown'} className={"item dropdown " + (branchesActive ? "current" : "")}
                    onClick={handleBranchesDropDown}>
                    {props.i18n.t('Research areas')}
                    {branchesDropDownOpen
                        ? <FontAwesomeIcon icon={faAngleUp}/>
                        : <FontAwesomeIcon icon={faAngleDown}/>
                    }
                    {isDesktop
                        ? <div id={'BranchMenuSmall'}
                               className={"dropdown-content " + (branchesDropDownOpen ? "active" : "")}>
                            {props.i18n.t('branches', {returnObjects: true}).map((item, index) => {
                                return <BranchWithoutRipple key={index} name={item.name} url={item.url}
                                                            img_path={item.img_path} className={item.className}
                                                            onClick={handleNavBar}/>;
                            })}
                        </div>
                        : <ul className={"dropdown-content " + (branchesDropDownOpen ? "active" : "")}>
                            {props.i18n.t('branches', {returnObjects: true}).map((item, index) =>
                                <li key={index}>
                                    <BranchSmall name={item.name} url={item.url} clickHandler={handleNavBar}/>
                                </li>
                            )}
                        </ul>
                    }
                </li>
                <li className="item" onClick={handleNavBar}><NavLink to="/news"
                                                                     activeClassName={'current'}>{props.i18n.t('News')}</NavLink>
                </li>
                <li className="item" onClick={handleNavBar}><NavLink to="/demos"
                                                                     activeClassName={'current'}>{props.i18n.t('Demos and resources')}</NavLink>
                </li>
                <li className="item" onClick={handleNavBar}><NavLink to="/collaboration"
                                                                     activeClassName={'current'}>{props.i18n.t('Collaboration')}</NavLink>
                </li>
                <li id={'teaching-dropdown'} className={"item dropdown " + (teachingActive ? "current" : "")}
                    onClick={handleTeachingDropDown}>
                    {props.i18n.t('Teaching')}
                    {teachingDropDownOpen
                        ? <FontAwesomeIcon icon={faAngleUp}/>
                        : <FontAwesomeIcon icon={faAngleDown}/>
                    }
                    <ul className={"dropdown-content " + (teachingDropDownOpen ? "active" : "")}>
                        <li onClick={handleNavBar}>
                            <Link to={"/teaching/courses"}>{props.i18n.t('Teaching Courses')}</Link>
                            <img className={'arrow'} src={arrow} alt={'Go'}/>
                        </li>
                        <li onClick={handleNavBar}>
                            <Link to={"/teaching/theses"}>{props.i18n.t('Theses')}</Link>
                            <img className={'arrow'} src={arrow} alt={'Go'}/>
                        </li>
                    </ul>
                </li>
                <li className="item" onClick={handleNavBar}><NavLink to="/contact"
                                                                     activeClassName={"current"}>{props.i18n.t('Contact')}</NavLink>
                </li>

                <li className="toggle" onClick={handleNavBar}>
                    {navBarOpen
                        ? <FontAwesomeIcon icon={faTimes} size={"lg"}/>
                        : <FontAwesomeIcon icon={faBars} size={"lg"}/>
                    }
                </li>
            </ul>

            {lang === 'et'
                ? (<ul className={'language-change ' + (navBarOpen ? "active" : "")}>
                        <li className="item lang-change" id="en" onClick={handleLangChange}>EN</li>
                        <li className="item lang-change" id="et" onClick={handleLangChange} style={style}>ET</li>
                    </ul>
                )
                : (<ul className={'language-change ' + (navBarOpen ? "active" : "")}>
                        <li className="item lang-change" id="en" onClick={handleLangChange} style={style}>EN</li>
                        <li className="item lang-change" id="et" onClick={handleLangChange}>ET</li>
                    </ul>
                )
            }
        </nav>
    );
}

const Header = (props) => {
    const [isSticky, setSticky] = useState(false);
    const ref = useRef(null);

    const handleScroll = _ => {
        setSticky(ref.current.getBoundingClientRect().top < window.pageYOffset);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll, true);
        return () => {
            window.removeEventListener("scroll", handleScroll, true);
        };
    }, []);


    return (
        <Fragment>
            <UTLogo language={props.i18n.language}/>
            <div className={`sticky-wrapper${isSticky ? ' sticky' : ''}`} ref={ref}>
                <Navigation i18n={props.i18n}/>
            </div>
        </Fragment>
    );
};

export default withTranslation()(Header);
