import React, {useState, useEffect} from 'react';
import {withTranslation} from "react-i18next";
import {wordpress_url} from "./variables";

function SubPage(props) {
    const [content, setContent] = useState('');
    const [title, setTitle] = useState('');

    useEffect(() => {
        const lang = props.i18n.language.split('-')[0];
        fetch(wordpress_url + '/pages/' + props.pageId + '?lang=' + lang)
            .then(response => response.json())
            .then(result => {
                setTitle(result.title.rendered);
                setContent(result.content.rendered);
            })
            .catch(e => console.error(e));
    }, [props.pageId, props.i18n.language]);

    if (title === '' || content === '') {
        return <div className={'loading'}>{props.t('Loading')}</div>
    }
    else {
        return (
            <div className={'App'}>
                <h1 className={'title'}>{title}</h1>
                <div className={'subpage'} dangerouslySetInnerHTML={{__html: content}}/>
            </div>
        );
    }
}

export default withTranslation()(SubPage);