import ReactGA from "react-ga";
import React, {useLayoutEffect, useState} from "react";

export function googleLocation() {
    ReactGA.set({page: window.location.pathname});
    ReactGA.pageview(window.location.pathname);
}

export function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}