import React, {useEffect, useState} from "react";
import './Contact.css';
import {useTranslation, withTranslation} from "react-i18next";
import myStyles from "./media/data/map-style";
import {base_url, MapsKey, wordpress_acf_url} from "./variables";
import {GoogleMap, Marker, withGoogleMap, withScriptjs} from "react-google-maps";
import {Formik} from "formik";
import * as Yup from 'yup';


const GoogleMapComponent = withScriptjs(
    withGoogleMap(props => (
        <GoogleMap
            defaultZoom={16}
            defaultCenter={{
                lat: 58.38548382579841,
                lng: 26.724909349999994
            }}
            defaultOptions={{
                disableDefaultUI: true,
                draggable: true,
                keyboardShortcuts: false,
                zoomControl: true,
                scrollwheel: true,
                styles: myStyles
            }}
        >
        <Marker position={{lat: 58.38548382579841, lng: 26.724909349999994}} />
    </GoogleMap>
)));

export const ContactForm = () => {
    const {t, } = useTranslation();

    return (
        <Formik
            initialValues={{name: '', email: '', message: ''}}
            validationSchema={Yup.object({
                name: Yup.string().required(t('Required')),
                email: Yup.string().email('Invalid email').required(t('Required')),
                message: Yup.string().required(t('Required'))
            })}
            onSubmit={(values, {resetForm}) => {
                fetch(base_url + '/send.php',{
                    method: "POST",
                    body: JSON.stringify(values, null, 2),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                })
                    .then(response => response.json())
                    .then(result => {
                        resetForm({values: ''});
                    })
                    .catch(e => {
                        console.error(e);
                        resetForm({values: ''});
                    });
            }}>
            {formik => (
            <form onSubmit={formik.handleSubmit}>
                <label htmlFor="name">
                    <input id="name" name="name" type="text" placeholder=" " value={formik.values.name}
                           onChange={formik.handleChange}/>
                    <span>{t('Name')}</span>
                </label>
                {formik.touched.name && formik.errors.name ? (
                    <div className={'message--error'}>{formik.errors.name}</div>
                ) : null}
                <label htmlFor="email">
                    <input id="email" name="email" type="email" placeholder=" "
                           value={formik.values.email} onChange={formik.handleChange}/>
                    <span>{t('E-mail')}</span>
                </label>
                {formik.touched.email && formik.errors.email ? (
                    <div className={'message--error'}>{formik.errors.email}</div>
                ) : null}
                <label htmlFor="message">
                    <textarea id="message" name="message" placeholder=" " value={formik.values.message}
                              onChange={formik.handleChange}/>
                    <span>{t('Message')}</span>
                </label>
                {formik.touched.message && formik.errors.message ? (
                    <div className={'message--error'}>{formik.errors.message}</div>
                ) : null}
                <button type="submit">{t("Send")}</button>
                <p id={'error'}>{t('error-text')}</p>
            </form>
        )}
        </Formik>
    );
};

function ContactPage() {
    const {t, i18n} = useTranslation();
    const [text, setText] = useState('');

    useEffect(() => {
        const lang = i18n.language.split('-')[0];
        fetch(`${wordpress_acf_url}/pages/1085?lang=${lang}`)
            .then(response => response.json())
            .then(result => {
                setText(result.acf.contact_text);
            })
            .catch(e => console.error(e))
    }, [i18n.language]);

    return (
        <div className={'App'}>
            <h2 className={'title contact'}>{t('Contact')}</h2>
            <div className={'addressblock'}>
                <h2>{t('Address')}</h2>
                <div>
                    <p>Narva mnt 18<br/>
                    51009 TARTU <br/>
                        {t('ESTONIA')}</p>
                    <GoogleMapComponent
                        googleMapURL={"https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=" + MapsKey +
                        "&region=" + i18n.language}
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `300px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                    />

                </div>
            </div>
            <div className={'addressblock'}>
                <h2>{t("Let's talk")}</h2>
                <div>
                    <p className={'letstalk'} dangerouslySetInnerHTML={{ __html: text }}>
                    </p>
                    <ContactForm />
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(ContactPage);