import React, {useState, useEffect} from 'react';
import './BranchPage.css';
import {ResearcherBlock} from "./People";
import {DemoItem} from "./DemoPage";
import {ProjectBlock} from "./CollaborationProject";
import {collaboration_category, projects_category, wordpress_url} from "./variables";
import arrow from "./media/img/right-arrow.svg";
import ReactSVG from "react-svg";
import {useTranslation} from "react-i18next";
import ReactHtmlParser, {convertNodeToElement} from "react-html-parser";

// Change all the paragraph tag classNames into "big"
function transform(node, index) {
    if (node.type === 'tag' && node.name === 'p') {
        node.attribs.class = 'big';
        return convertNodeToElement(node, index, transform);
    }
}

const options = {
    decodeEntities: true,
    transform
};

const TitleAndDescription = ({title, description, image}) => {
    //if (description !== undefined) description.map(item => console.log(item));

    return (
        <div className={'description branch'}>
            <h1 className={'title'}>{title}</h1>
            <div>
                {image !== undefined ? <ReactSVG src={require('./media/img/' + image)} className={'empty-title svgBranch'}/> : null}
                <div>{description}</div>
            </div>
        </div>
    );
};

function Demos({name, items, className}) {
    if (items.length <= 0) {
        return null;
    }
    else {
        return (
            <div className={'demoblock branch'}>
                <h2 className={className}>{name}</h2>
                <div>
                    {items.map((item, key) => <DemoItem className={'branch ' + item.className} img_path={item.img_path}
                                                        name={item.name}
                                                        description={item.description} key={key} url={item.url} />)}
                </div>
            </div>
        );
    }
}

function ProjectItem({name, grant, lead, url}) {
    const {t} = useTranslation();
    return (
        <div className={'projectitem'}>
            <a href={url} rel="noopener noreferrer" target={"_blank"}>
                <h3>{name}</h3>
                <div className={'description'}>
                    <p>{grant}</p>
                    <div className={'circle'}/>
                    <p>{t('project_lead')}: <span className={'bold'}>{lead}</span></p>
                    <img className={'arrow'} src={arrow} alt={'Go'}/>
                </div>
            </a>
        </div>
    );
}

function Projects({name, tag}) {
    const [items, setItems] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const {t, i18n} = useTranslation();

    useEffect(() => {
        const lang = i18n.language.split("-")[0];
        fetch(`${wordpress_url}/posts?categories=${projects_category}&tags=${tag}&lang=${lang}`)
            .then(response => response.json())
            .then(result => {
                let projects = [];
                result.forEach(project => {
                    const name = project.title.rendered;
                    const grant = project.acf.code;
                    const lead = project.acf.leader;
                    const url = project.acf.link;
                    projects.push({name: name, grant: grant, lead: lead, url: url});
                });
                //console.log(projects);
                setItems(projects);
            })
            .catch(err => console.error(err));
    }, [tag, i18n.language]);

    if (items.length <= 0) {
        return null;
    }
    else {
        return (
            <div className={'projectblock branch'}>
                <h2 className={'small-title'}>{name}</h2>
                <div>
                    {items.map((item, key) => <ProjectItem name={item.name} grant={item.grant} lead={item.lead}
                                                           url={item.url}
                                                           key={key}/>)}
                </div>
            </div>
        );
    }
}

function Researchers({pageId}) {
    const {t} = useTranslation();
    return (
        <div className={'addressblock researchers small-branch'}>
            <h2 className={'small-title'}>{t('Researchers')}</h2>
            <ResearcherBlock pageId={pageId}/>
        </div>
    );
}

function BranchPage({data}) {
    const {t, i18n} = useTranslation();
    const [description, setDescription] = useState();

    useEffect(() => {
        const lang = i18n.language.split('-')[0];
        fetch(`${wordpress_url}/pages/${data.id}?lang=${lang}`)
            .then(response => response.json())
            .then(result => {
                const parsedHtml = ReactHtmlParser(result.content.rendered, options);
                setDescription(parsedHtml);
            })
            .catch(err => console.error(err));
    }, [data, i18n.language]);

    return (
        <div className={'App'}>
            <TitleAndDescription title={data.name} description={description} image={data.image}/>
            <Demos name={t('Demos')} items={data.demos} className={'small-title'}/>
            <Projects name={t('Research projects')} tag={data.tag}/>
            <ProjectBlock name={t('Collaboration projects')} category_nr={collaboration_category}
                          className={'small-branch'} i18n={i18n} tag={data.tag}/>
            <Researchers pageId={data.id}/>
        </div>
    );
}

export default BranchPage;
