import React, {Fragment, useEffect, useState} from "react";
import './People.css';
import Carousel from "react-multi-carousel";
import {Link} from "react-router-dom";
import rightarrow from "./media/img/right-arrow.svg";
import {
    automatic_processing_people, language_resources_people,
    machine_learning_people, people_page,
    semantics_people,
    wordpress_url
} from "./variables";
import {Trans, useTranslation} from "react-i18next";
import ReactSVG from "react-svg";
import ReactHtmlParser from "react-html-parser";

export const PeopleBlock = ({ className, peopleText, imageUrl}) => {
    const {t} = useTranslation();

    let style;
    if (typeof imageUrl !== 'undefined') {
        style = {backgroundImage: `linear-gradient(195.53deg, rgba(40, 40, 40, 0) 0%, rgba(40, 40, 40, 0.85) 59.51%, #282828 100%), url('${imageUrl}')`};
    }
    else {
        style = null;
    }

    return (
        <Fragment>
            <div className={'people'} style={style} />
            <div className={'peopleblock ' + className}>
                <h1 className={'title vertical vertical2 peopletitle'}>{t('Our people')}</h1>
                <div className={'moveDown'}>
                    <p className={'big'}>{peopleText}</p>

                    <button>
                        <Link to={'/people'} className={'button-href'}>
                            {t('Meet the team')}
                            <ReactSVG src={rightarrow} alt={t('Go')} className={'arrow'}/>
                        </Link>
                    </button>
                </div>
            </div>
            <PeopleSlider className={className}/>
        </Fragment>
    );
};

const PeopleSlider = ({className}) => {
    const [people, setPeople] = useState([]);
    const {t, i18n} = useTranslation();

    useEffect(() => {
        const lang = i18n.language.split('-')[0];
        const idArray = [semantics_people, machine_learning_people, automatic_processing_people, language_resources_people];
        const stringArray = idArray.join(',');
        const rightUrl = wordpress_url + '/pages?parent=' + stringArray + '&orderby=menu_order&order=asc&per_page=100&lang='
            + lang;
        fetchPeople(rightUrl)
            .then(res => {
                setPeople(res);
            })
            .catch(err => console.error(err));
    }, [i18n.language]);

    const responsive = {
        desktop_large: {
            breakpoint: {max: 3000, min: 2400},
            items: 6,
            partialVisibilityGutter: 50
        },
        desktop: {
            breakpoint: {max: 2399, min: 1440},
            items: 5,
            partialVisibilityGutter: 50,
        },
        tablet_large: {
            breakpoint: {max: 1439, min: 1200},
            items: 4,
            partialVisibilityGutter: 50
        },
        tablet: {
            breakpoint: {max: 1199, min: 601},
            items: 3,
            partialVisibilityGutter: 50
        },
        mobile: {
            breakpoint: {max: 600, min: 481},
            items: 1,
            partialVisibilityGutter: 50,
        },
        mobile_small: {
            breakpoint: {max: 480, min: 0},
            items: 0
        }
    };

    return people ? (
        <Carousel
            infinite={true}
            responsive={responsive}
            centerMode={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            transitionDuration={1000}
            arrows={false}
            className={className}
            swipeable={false}
            draggable={false}
            focusOnSelect={false}
        >
            {people.map(person => (<PersonSmall key={person} person={person}/>))}
        </Carousel>
    ) : (
        <div className={'loading'}>{t('Loading')}</div>
    );
};

const PersonSmall = ({person}) => {
    return (
        <div key={person.name} className={'person-small'}>
            <img src={person.picture.url} alt={'Person'}/>
            <p className={'name'}>{person.name}</p>
            <p>{person.title}</p>
        </div>
    );
};

const Person = ({person}) => {
    const {t} = useTranslation();
    const etis = person.etis_profile_link !== '' ? t('ETIS profile') : '';
    const email = person.email_address !== '' ? 'mailto:' + person.email_address : '';
    return (
        <div key={person.name} className={'person'}>
            <img src={person.picture.url} alt={'Person'}/>
            <p className={'name'}>{person.name}</p>
            <p>{person.title}</p>
            <p><a href={email}>{person.email_address}</a></p>
            <p>{person.phone_number}</p>
            <p>{person.room_number}</p>
            <p><a href={person.etis_profile_link}>{etis}</a></p>
        </div>
    );
};

const fetchPeople = (url) => {
    return new Promise((resolve, reject) => {
        fetch(url)
            .then(response => response.json())
            .then(data => {
                let peopleJson = [];
                for (let i = 0; i < data.length; i++) {
                    peopleJson.push(data[i].acf);
                }

                resolve(peopleJson);
            })
            .catch(err => reject(err));
    });
};

export const ResearcherBlock = ({pageId}) => {
    const [people, setPeople] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const {t, i18n} = useTranslation();

    useEffect(() => {
        let url = wordpress_url + '/pages';
        const lang = i18n.language.split('-')[0];
        /* If the pageID is not specified, then fetch all researchers. Otherwise fetch only specific branch researchers */
        if (pageId === '') {
            const idArray = [semantics_people, machine_learning_people, automatic_processing_people, language_resources_people];
            const stringArray = idArray.join(',');
            const rightUrl = url + '?parent=' + stringArray + '&orderby=menu_order&order=asc&per_page=100&lang=' + lang;
            fetchPeople(rightUrl)
                .then(res => setPeople(res))
                .catch(err => console.error(err));
        } else {
            const rightUrl = url + '?parent=' + pageId.toString() + '&orderby=menu_order&order=asc&per_page=100&lang=' + lang;
            fetchPeople(rightUrl)
                .then(res => setPeople(res))
                .catch(err => console.error(err));
        }

    }, [pageId, i18n.language]);

    return (
        <div className={'researcherblock'}>
            {people.map((person, index) => (<Person person={person} key={index} i18n={i18n}/>))}
        </div>
    );
};

export function PeoplePage() {
    const {t, i18n} = useTranslation();
    const [text, setText] = useState();
    const [accolades, setAccolades] = useState();

    useEffect(() => {
        const lang = i18n.language.split('-')[0];
        fetch(wordpress_url + "/pages/" + people_page + "?lang=" + lang)
            .then(response => response.json())
            .then(result => {
                const parsedHtmlText = ReactHtmlParser(result.content.rendered);
                setText(parsedHtmlText);
                setAccolades(result.acf.accomplishments);
            })
            .catch(err => console.error(err));
    }, [i18n.language]);

    return (
        <div className={'App researchers'}>
            <h1 className={'title'}>{t('Researchers')}</h1>
            <div className={'demoblock'}>
                <h2 className={'empty-title'}> </h2>
                {text}
            </div>
            {/* The accomplishments part is commented out because there is no text for it.
               <div className={'demoblock'}>
                <h2 className={'small-title'} lang={i18n.language}><Trans i18nKey={'Acknowledgments'}>Acknowl&shy;edgments</Trans></h2>
                <div className={'accolations'}>
                    <p className={'big'} dangerouslySetInnerHTML={{__html: accolades}}/>
                </div>
            </div>*/}
            <div className={'addressblock researchers'}>
                <h2 className={'small-title'}>{t('Researchers')}</h2>
                <ResearcherBlock pageId={''} i18n={i18n}/>
            </div>
        </div>
    );
}