import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {wordpress_url} from "./variables";
import {useTranslation} from "react-i18next";

const SlugPage = () => {
    const { t, i18n} = useTranslation();
    let { slug } = useParams();
    let [data, setData] = useState([]);
    let [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const lang = i18n.language.split("-")[0];
        fetch(`${wordpress_url}/pages?slug=${slug}&lang=${lang}`)
            .then(response => response.json())
            .then(result => {
                if (result.length !== 0) setData(result[0]);
                setLoading(false);
            })
            .catch(e => {
                console.error(e);
                setLoading(false);
            })
    }, [slug, i18n.language]);

    if (loading) {
        return (
            <div className={'article'}>Loading!!</div>
        )
    }
    else {
        if (data.length !== 0) {
            return (
                <div className={'article'}>
                    <h2>{data.title.rendered}</h2>
                    <p className={'content'} dangerouslySetInnerHTML={{__html: data.content.rendered}}/>
                </div>
            );
        } else {
            return (
                <div className={'article'}>
                    <h3 className={'notfound'}>{t('nothing found')}</h3>
                </div>
            );
        }
    }
}

export default SlugPage;