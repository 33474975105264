import React, {useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import './News.css';
import {news_category, wordpress_url} from "./variables";
import arrow from './media/img/left-arrow.svg';
import {useTranslation} from "react-i18next";
import {useMediaQuery} from "react-responsive";

//const shortDateOptions = { year: "numeric", month: "2-digit", day: "2-digit" };
export const longDateOptions = {year: "numeric", month: "long", day: "numeric"};

export function Article({match, i18n}) {
    const [news, setNews] = useState([]);
    const isDesktopOrTablet = useMediaQuery({query: '(min-width: 768px)'});
    const history = useHistory();

    useEffect(() => {
        const lang = i18n.language.split('-')[0];
        fetch(`${wordpress_url}/posts/${match.params.articleId}?lang=${lang}`)
            .then(response => response.json())
            .then(data => {
                const formattedDate = new Date(data.date);
                const newNews = {
                    title: data.title.rendered,
                    date: formattedDate.toLocaleDateString(i18n.language + '-' + i18n.language.toUpperCase(), longDateOptions),
                    //date: formattedDate.toLocaleDateString('et-ET', shortDateOptions),
                    content: data.content.rendered,
                    image: data.fimg_url,
                };

                setNews(newNews);
            })
            .catch(e => {
                console.error(e);
                history.push('/not-found');
            });
    }, [match.params.articleId, i18n.language, history]);

    return (
        <div className={'article'}>
            <img src={news.image} alt={''}/>
            <div>
                {!isDesktopOrTablet
                    ?
                    null
                    :
                    <div className={'backbutton'}>
                        <Link to="/news">
                            <img src={arrow} alt={''}/>
                            <p>{i18n.t('Back')}</p>
                        </Link>
                    </div>
                }
                <div className={'articlecontent'}>
                    <h2>{news.title}</h2>
                    <p className={'date'}>{news.date}</p>
                    <p className={'content'} dangerouslySetInnerHTML={{__html: news.content}}/>
                </div>
            </div>
        </div>
    );
}

export function NewsSmall({id, title, date, excerpt, image, match}) {
    return (
        <div key={id} className={'smallnews'}>
            <div className={'imagecontainer'}>
                <Link to={`${match.url}/article-${id}`}><img src={image} alt={''}/></Link>
            </div>
            <h3><Link to={`${match.url}/article-${id}`}>{title.rendered}</Link></h3>
            <p className={'date'}>{date}</p>
            <p className={'excerpt'} dangerouslySetInnerHTML={{__html: excerpt.rendered}}/>
        </div>
    );
}

function NewsPage({match}) {
    const [news, setNews] = useState([]);
    const [year, setYear] = useState(0);
    const [allYears, setAllYears] = useState([]);
    const isDesktopOrTablet = useMediaQuery({query: '(min-width: 768px)'});
    const {t, i18n} = useTranslation();

    useEffect(() => {
        const lang = i18n.language.split('-')[0];
        if (year === 0) {
            fetch(wordpress_url + '/posts?&categories=' + news_category + '&lang=' + lang)
                .then(response => response.json())
                .then(data => {
                    let newsJson = [];
                    let yearsSet = new Set();
                    yearsSet.add(0);
                    for (let i = 0; i < data.length; i++) {
                        let formattedDate = new Date(data[i].date);
                        const locale = lang === 'et' ? 'et-ET' : 'en-US';
                        data[i].date = formattedDate.toLocaleDateString(locale, longDateOptions);
                        newsJson.push(data[i]);
                        yearsSet.add(formattedDate.getFullYear());
                    }
                    setNews(newsJson);

                    let yearsArray = [];
                    Array.from(yearsSet).map(el => {
                        if (el === 0) {
                            return yearsArray.push({value: el, label: t('All')})
                        } else {
                            return yearsArray.push({value: el, label: el})
                        }
                    });
                    setAllYears(yearsArray);
                });
        } else {
            fetch(`${wordpress_url}/posts?categories=${news_category}&lang=${lang}&after=${year.toString()}-01-01T00:00:00&before=${year.toString()}-12-31T23:59:59`)
                .then(response => response.json())
                .then(data => {
                    console.log(data);
                    let newsJson = [];
                    for (let i = 0; i < data.length; i++) {
                        let formattedDate = new Date(data[i].date);
                        const locale = lang === 'et' ? 'et-ET' : 'en-US';
                        data[i].date = formattedDate.toLocaleDateString(locale, longDateOptions);
                        newsJson.push(data[i]);
                    }
                    setNews(newsJson);
                })
        }
    }, [year, i18n.language, t]);

    function handleFilter(e) {
        if (e.value === 0) {
            setYear(0);
        } else {
            setYear(e.value);
        }
    }

    if (news === '') {
        return <div className={'loading'}>{t('Loading')}</div>;
    } else {
        return (
            <div className={'App'}>
                <h1 className={'title'}>{t('News')}</h1>
                <div className={'flexdiv'}>
                    {isDesktopOrTablet
                        ? <ul className={'newsfilter'}>
                            {allYears.map((item, index) => <li key={index}
                                                               className={item.value === year ? "active-news" : ""}
                                                               onClick={() => handleFilter(item)}>{item.label}</li>)}
                        </ul>
                        : <Dropdown
                            options={allYears}
                            onChange={handleFilter}
                            value={year === 0 ? t('All') : year.toString()}
                            className={'newsfilter'}
                            controlClassName={'newsfilter-control'}
                            placeholderClassName={'newsfilter-placeholder'}
                            arrowClassName={'newsfilter-arrow'}
                            menuClassName={'newsfilter-menu'}
                        />
                    }
                    {news.length === 0
                        ? <div className={'newscolumn'}>
                            {t('No news')}
                        </div>
                        : <div
                            className={'newscolumn'}>
                            {news.map(({id, title, date, excerpt, fimg_url}) => (
                                <NewsSmall key={id} id={id} title={title} date={date} excerpt={excerpt} image={fimg_url}
                                           match={match}/>
                            ))}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default NewsPage;