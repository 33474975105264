import React, {useEffect, useState} from "react";
import {wordpress_url} from "./variables";
import {useTranslation} from "react-i18next";

function ProjectItem({name, date, partner, description, style}) {
    const {t} = useTranslation();
    if (partner.length !== 0) {
        let partnerText;
        try {
            partnerText = partner.length >=2 ? t('partner_plural') + ': ' : t('partner') + ': ';
        } catch (e) {
            partnerText = t('partner') + ': ';
        }
        const listItems = partner.map((item) => <a href={item.partner_link} rel="noopener noreferrer" target="_blank" key={item.partner}>{item.partner}</a>);
        const joinedListItems = listItems.reduce((prev, curr) => [prev, ', ', curr]);

        return (
            <div className={'projectitem'} style={style}>
                <h3>{name}</h3>
                <div className={'description'}>
                    <p>{date}</p>
                    <div className={'circle'}/>
                    <div className={'partner-description'}>
                        <p>{partnerText}</p><p className={'partner'}>{joinedListItems}</p>
                    </div>
                </div>
                <p dangerouslySetInnerHTML={{__html: description}}/>
            </div>
        );
    }
    else {
        return (
            <div className={'projectitem'} style={style}>
                <h3>{name}</h3>
                <div className={'description'}>
                    <p>{date}</p>
                </div>
                <p dangerouslySetInnerHTML={{__html: description}}/>
            </div>
        );
    }
}

export function ProjectBlock({name, category_nr, className, i18n, tag}) {
    const [projects, setProjects] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const lang = i18n.language.split("-")[0];
        let url = `${wordpress_url}/posts?categories=${category_nr}&lang=${lang}`;
        if (tag) {
            url = url + '&tags=' + tag;
        }

        setIsLoading(true);
        fetch(url)
            .then(response => response.json())
            .then(data => {
                let projects = [];

                for (let i = 0; i < data.length; i++) {
                    const oneProject = {};
                    oneProject.name = data[i].title.rendered;
                    oneProject.date = data[i].acf.date;
                    oneProject.description = data[i].content.rendered;
                    oneProject.partner = data[i].acf.partners !== false ? data[i].acf.partners : [];
                    projects.push(oneProject);
                }
                setProjects(projects);
                setIsLoading(false);
            });
    }, [category_nr, tag, i18n.language]);

    const lastStyle = {
        border: 'none',
    };

    const blocks = projects.map(({name, date, partner, description}, index) => {
        if (index === projects.length - 1) {
            return <ProjectItem style={lastStyle} key={index} name={name} description={description} date={date} partner={partner}/>
        }
        else {
            return <ProjectItem key={index} name={name} description={description} date={date} partner={partner}/>
        }
    });

    if (isLoading) {
        return <div className={'loading'}>{i18n.t('Loading')}</div>
    }

    if (projects.length === 0) {
        return null;
    }

    return (
        <div className={'demoblock collab ' + className}>
            {/*style={{width: '260px', paddingTop: '30px'}}*/}
            <h2 className={'small-title'}>{name}</h2>
            <div>{blocks}</div>
        </div>
    );
}