export const wordpress_url = 'https://tartunlp.ai/wordpress/index.php/wp-json/wp/v2';
export const wordpress_acf_url = 'https://tartunlp.ai/wordpress/index.php/wp-json/acf/v3';
export const base_url = 'https://tartunlp.ai';

export const news_category = 1;
export const collaboration_category = 6;
export const projects_category = 26;

export const semantics_people = 87;
export const semantics_tag = 27;
export const machine_learning_people = 89;
export const machine_learning_tag = 28;
export const automatic_processing_people = 91;
export const automatic_processing_tag = 30;
export const language_resources_people = 95;
export const language_resources = 29;

export const people_page = 20;
export const resources_page = 625;
export const courses_page = 390;
export const theses_page = 384;

export const MapsKey = 'AIzaSyDip3m3lhmEEFtTxKM8SYcwxycbpaNOEQU';
export const analyticsKey = 'UA-157597782-1';
