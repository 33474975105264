import React from "react";
import {useTranslation} from "react-i18next";

function NotFoundPage () {
    const {t} = useTranslation();
    return (
        <div className={'App'}>
            <h3 className={'notfound'}>{t('nothing found')}</h3>
        </div>
    );
}

export default NotFoundPage;