import {Route, Switch} from "react-router-dom";
import App from "./App";
import BranchPage from "./BranchPage";
import NewsNavRoute from "./NewsNavRoute";
import DemoPage from "./DemoPage";
import CollaborationPage from "./CollaborationPage";
import ContactPage from "./ContactPage";
import {PeoplePage} from "./People";
import SubPage from "./SubPage";
import {analyticsKey, courses_page, theses_page} from "./variables";
import SlugPage from "./SlugPage";
import NotFoundPage from "./NotFoundPage";
import React, {useEffect} from "react";
import {Trans, useTranslation} from "react-i18next";
import CookieConsent, {Cookies} from "react-cookie-consent";
import ReactGA from "react-ga";
import {useLocation} from "react-router";
import {googleLocation} from "./functions";
import ScrollToTop from "./ScrollToTop";
import Header from "./Header";
import Footer from "./Footer";

function Routing() {
    const {t} = useTranslation();
    const semantics = t('semantics_page', {returnObjects: true});
    const machinelearning = t('machinelearning_page', {returnObjects: true});
    const automaticprocessing = t('automaticprocessing_page', {returnObjects: true});
    const languageresources = t('languageresources_page', {returnObjects: true});

    return (
        <Switch>
            <Route exact path="/" component={App}/>
            <Route path="/branches/machine-learning"
                   render={(props) => <BranchPage {...props} data={machinelearning}/>}/>
            <Route path="/branches/automatic-processing"
                   render={(props) => <BranchPage {...props} data={automaticprocessing}/>}/>
            <Route path="/branches/semantics" render={(props) => <BranchPage {...props} data={semantics}/>}/>
            <Route path="/branches/language-resources"
                   render={(props) => <BranchPage {...props} data={languageresources}/>}/>
            <Route path="/news" component={NewsNavRoute}/>
            <Route path="/demos" component={DemoPage}/>
            <Route path="/collaboration" component={CollaborationPage}/>
            <Route path="/contact" component={ContactPage}/>
            <Route path="/people" component={PeoplePage}/>
            <Route path="/teaching/courses" render={(props) => <SubPage {...props} pageId={courses_page}/>}/>
            <Route path="/teaching/theses" render={(props) => <SubPage {...props} pageId={theses_page}/>}/>
            <Route path="/:slug" children={<SlugPage/>}/>
            <Route component={NotFoundPage}/>
        </Switch>
    );
}

function Main() {
    const {t, } = useTranslation();

    useEffect(() => {
        const cookie = Cookies.get('TartuNLP');
        if (cookie) {
            ReactGA.initialize(analyticsKey);
        }
    }, []);

    // If URL changes, send data to Google Analytics
    const location = useLocation();
    useEffect(() => {
        const cookie = Cookies.get("TartuNLP");
        if (cookie) googleLocation();
    }, [location]);

    return (
        <>
            <ScrollToTop />
            <Header />
            <Routing />
            <Footer />
            <CookieConsent
                location="bottom"
                cookieName="TartuNLP"
                sameSite="strict"
                buttonText={t("cookie button")}
                containerClasses={"cookie-container"}
                contentClasses={"text--cookie"}
                buttonClasses={"button--cookie"}
                disableButtonStyles={true}
                contentStyle={{
                    marginLeft: "0",
                    marginRight: "0",
                    paddingRight: "10px",
                    flexBasis: "auto",
                    boxSizing: "border-box"
                }}

                onAccept={() => {
                    ReactGA.initialize(analyticsKey);
                    googleLocation();
                }}
            >
                <Trans i18nKey={'cookie consent'}>
                    This website uses <a href={'/andmekaitsetingimused'} rel="noopener noreferrer" target={'_blank'}>cookies</a>. Using this site, you agree to the cookies.
                </Trans>
            </CookieConsent>
        </>
    );
}

export default Main;