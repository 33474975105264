import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './App.css';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n";
import Main from "./Main";
import {createBrowserHistory as createHistory} from 'history';

/*import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
LogRocket.init('ff4jqz/tartunlp');
setupLogRocketReact(LogRocket);*/

export const history = createHistory();

ReactDOM.render(
    <Router history={history}>
        <I18nextProvider i18n={i18n}>
            <Main />
        </I18nextProvider>
    </Router>,
    document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
