import React from "react";
import ReactSVG from "react-svg";
import './Footer.css';
import facebookLogo from './media/img/facebook_logo.svg';
import githubLogo from './media/img/github_logo.svg';
import huggingFaceLogo from './media/img/huggingface_logo_purple_circle_inverted.svg';
import tyLogo from './media/img/tartu_ylikool-ringlogo-valge-est-eng.svg';
import logo from './media/img/Tartunlp_logo.svg';
import mobileFooterPattern from './media/img/mobile-footer-pattern.svg';
import leftFooterPattern from './media/img/footer-pattern-left.svg';
import rightFooterPattern from './media/img/footer-pattern-right.svg';
import {Trans, withTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const Footer = (props) => {
    return (
        <footer>
            <div className={'padded-footer'}>
                <div className={'address-information'}>
                    <img src={logo} alt={"Tartu NLP"} id={'logo'}/>
                    <p>Narva mnt 18</p>
                    <p>51009 TARTU</p>
                    <p>{props.t('ESTONIA')}</p>
                    <p><a href={'mailto:ping@tartunlp.ai'} target={'_blank'} rel="noopener noreferrer">ping@tartunlp.ai</a></p>
                </div>
                <div className={'quick-links'}>
                    <p className={'small-paragraph'}>{props.t('Quick links')}</p>
                    <p><a href={'/news'}>{props.t('News')}</a></p>
                    <p><a href={'/collaboration'}>{props.t('Collaboration')}</a></p>
                    <p><Link to={'/teaching/theses'}>{props.t('Thesis topics')}</Link></p>
                    <p><Link to={'/teaching/courses'}>{props.t('Courses')}</Link></p>
                    <p><Link to={'/contact'}>{props.t('Contacts')}</Link></p>
                </div>
                <div className={'social-media'}>
                    <p className={'small-paragraph'}>{props.t('We are active on social media')}</p>
                    <div>
                        <a href={'https://www.facebook.com/tartunlp/'} target={'_blank'} rel="noopener noreferrer"><ReactSVG src={facebookLogo} className={'social-media-logo'}/></a>
                        <a href={'https://github.com/TartuNLP'} target={'_blank'} rel="noopener noreferrer"><ReactSVG src={githubLogo} className={'social-media-logo'}/></a>
                        <a href={'https://huggingface.co/tartuNLP'} target={'_blank'} rel={"noopener noreferrer"}><ReactSVG src={huggingFaceLogo} className={'social-media-logo'} /></a>
                    </div>
                </div>
                <div className={'alma-mater'}>
                    <p className={'small-paragraph'}><Trans i18nKey={'Alma mater'}>Our <em>alma mater</em> is the University of Tartu</Trans></p>
                    <a href={"https://www.ut.ee/"} rel="noopener noreferrer" target="_blank">
                        <ReactSVG src={tyLogo} alt={"Tartu Ülikool / University of Tartu"}/>
                    </a>
                </div>
            </div>
            <div className={'footer-patterns'}>
                <ReactSVG className={'footerLeftPattern'} src={leftFooterPattern}/>
                <p className={'small-paragraph'}>&#9400;2020 TartuNLP</p>
                <ReactSVG className={'footerRightPattern'} src={rightFooterPattern}/>
                <ReactSVG className={'footerMobilePattern'} src={mobileFooterPattern}/>
            </div>
        </footer>
    );
};

export default withTranslation()(Footer);