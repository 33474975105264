import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import translationEng from "./locales/en/translation.json";
import translationEst from "./locales/et/translation.json";

i18n
    .use(XHR)
    .use(LanguageDetector)
    .init({
        debug: false,
        lng: 'et',
        fallbackLng: "et",

        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false, // not needed for react!!
        },

        react: {
            wait: true
        },

        resources: {
            en: {
                translations: translationEng
            },
            et: {
                translations: translationEst
            },
        },
});

export default i18n;